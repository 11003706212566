<template>
  <v-layout class="mt-3" align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card>
        <v-card-title class="header">{{ $t("message.title") }}</v-card-title>
        <v-card-text v-if="initializing" class="text-center">
          <v-progress-circular
            indeterminate
            size="64"
            class="mx-2"
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("message.name") }}</v-list-item-title>
                <v-list-item-subtitle>{{ applicantName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("message.contact")
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ contact }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">{{ $t("message.dates") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in dates"
                v-bind:key="index"
              >
                <td>{{ `${data.isoDate} ${data.startTime}` }}</td>
                <td>
                  <v-btn
                    outlined
                    small
                    :disabled="loading"
                    :loading="loading && selectedDate === index"
                    v-if="showSelect"
                    class="mr-4"
                    @click="confirmDate(index, data)"
                    >{{ $t("message.select") }}</v-btn
                  >
                  <v-icon v-if="!showSelect && selectedDate === index"
                    >mdi-check</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <br />
          <p>{{ $t("message.footer") }}</p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import '@appsocially/timepassport/dist/timepassport.css'
import { getApplicantSuggestedDates, confirmApplicantSuggestedDates } from '@/helpers/firebase-functions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
  },
  props: {
    applicantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      applicantName: '',
      contact: '',
      dates: [],
      loading: false,
      selectedDate: '',
      showSelect: true,
      initializing: true,
      proposedDateId: null
    }
  },
  methods: {
    confirmDate (dateId, data) {
      this.selectedDate = data.id
      this.loading = true
      confirmApplicantSuggestedDates({
        applicantId: this.applicantId,
        proposedDateId: this.$route.query.proposedDateId || this.proposedDateId,
        dateId: data.id,
        data
      }).then(result => {
        this.showSelect = false
        this.loading = false
      })
    }
  },
  created () {
    getApplicantSuggestedDates({
      applicantId: this.applicantId,
      proposedDateId: this.$route.query.proposedDateId
    }).then(({ data }) => {
      const { applicantName, contact, dates, id } = data
      this.proposedDateId = id
      this.applicantName = applicantName
      this.contact = contact
      this.dates = dates
      dates.filter(({ confirmed }) => confirmed).forEach(({ id }) => {
        this.showSelect = false
        this.selectedDate = id
      })

      this.initializing = false
    })
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_OWNER_INTERVIEW_CONFIRM)
  },
  i18n: {
    messages: {
      en: { message: {
        title: 'Proposed Dates',
        name: 'Name',
        contact: 'Contact',
        select: 'Select',
        dates: 'Dates',
        footer: 'If proposed date and time is not convenient, please contact the applicant directly and adjust the date and time.'
      } },
      ja: { message: {
        title: '応募者様からのご提案',
        name: '応募者',
        contact: '連絡先',
        select: '選択',
        dates: '候補日時',
        footer: '※いただいた提案日時で都合がつかない場合は、応募者様へ直接ご連絡のうえ日時を調整していただきますようお願い申し上げます'
      } }
    }
  }
}
</script>
